/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

.p-checkbox {
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    vertical-align: bottom;
    width: 22px;
    height: 22px;

    .p-checkbox-label {
        margin-left: 0.5rem;
    }

    .p-checkbox-box {
        border: 2px solid #ced4da;
        background: #fff;
        width: 22px;
        height: 22px;
        color: #495057;
        border-radius: 6px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

        .p-highlight {
            background: #3B82F6;
            border-color: 2px solid #ced4da;
        }

        .p-checkbox-icon {
            color: #fff;
            font-size: 14px;
            transition-duration: 0.2s;
        }
    }

    // Non-disabled checkbox.
    &:not(.p-checkbox-disabled) {
        &:hover {
            border-color: #3B82F6;
        }

        .p-checkbox-box {
            .p-focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.1rem white;
                border-color: #3B82F6;
            }

            .p-highlight {
                &:hover {
                    border-color: #3B82F6;
                    background: #3B82F6;
                    color: #495057;
                }
            }
        }
    }
}