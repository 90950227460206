/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


@import "src/assets/styles/styles";


.admin-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 100%;
    min-height: 200px;
    max-height: 200px;

    background-color: $springbok-black;
}

.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
    padding: 5px 50px;

    .heading-container {
        h1 {
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 1rem;
            color: rgba(0, 0, 0, 0.9);
        }

        p {
            color: #636363;
        }
    }

    .controls-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        input {
            min-width: 15rem;
            height: 2.25rem;
            border-radius: 8px;
        }

        button {
            display: flex;
            font-size: 1.1rem;
            transition: all 0.3s ease-in-out;
            border-radius: 8px;
            background: rgba(0, 0, 255, 0.1);
            color: rgba(0, 0, 255, 0.66);
            text-decoration: none !important;
            font-weight: 500;
            padding: 0.5rem 1.5rem;
            width: 100%;
            height: 2.25rem;

            &:hover {
                background: rgba(0, 0, 255, 0.2) !important;
                color: rgba(0, 0, 255, 0.85) !important;
            }
        }
    }
}