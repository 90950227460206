.p-dialog {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.12);

    .p-dialog-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        background: #fff;
        color: #000;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        padding: 5px;

        .p-dialog-title {
            margin-left: 10px;
            font-weight: 600;
            font-size: 14px;
        }

        .p-dialog-header-icon {
            width: 2rem;
            height: 2rem;
            color: rgba(45, 45, 45, 0.50);
            border: 0 none;
            background: transparent;
            border-radius: 50%;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            margin-right: 0.5rem;

            &:enabled {
                &:hover {
                    border-color: transparent;
                    background: transparent;
                }
            }

            &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.1rem white;
            }
        }
    }

}


.p-dialog .p-dialog-content {
    background: rgb(237, 239, 242);
    color: #000;
    padding-top: 25px;
}


.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.p-dialog .p-dialog-footer {
    background: rgb(237, 239, 242);
    color: #dedede;
    padding: 0.571rem 1rem;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}
