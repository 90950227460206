.p-tabview .p-tabview-nav {
  background: transparent;
  border: 0 none;
  border-width: 1px;
}
.p-tabview .p-tabview-nav li {
  margin-right: 2px;
  font-size: 14px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}


.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent;
  color: lighten(#131313, 50%);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

// Non-Selected tab style.
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  background: transparent;
  color: #131313;
  padding: 0.857rem 1rem;
  font-weight: 500;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
}

// Selected tab style.
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 5px solid #355de1;
  color: #355de1;
  font-weight: 500;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: #FFE082;
  color: #212529;
  width: 2.357rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tabview .p-tabview-panels {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background: transparent;
  padding: 0;
  color: #131313;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}