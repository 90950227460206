/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


.layout-collapsed {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 60px; // 60px is the width of the collapsed sidebar
    min-height: 100vh;
    height: 100%;

    .layout-application-window {
        width: calc(100vw - 60px);
        height: calc(100vh - 50px);
        overflow-y: scroll;
    }

    .project-select-dialog {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        position: relative;
        min-height: 100vh;
        width: calc(100% - 60px);
        margin-left: 60px;
        background-color: #eaeaea !important;
    }
}

.layout-expanded {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 220px; // 220px is the width of the collapsed sidebar
    min-height: 100vh;
    height: 100%;


    .layout-application-window {
        width: calc(100vw - 220px);
        height: calc(100vh - 50px);
        overflow-y: scroll;
    }

    .project-select-dialog {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        position: relative;
        min-height: 100vh;
        width: calc(100% - 250px);
        margin-left: 250px;
        background-color: #eaeaea !important;
    }
}

.light {
    .layout-expanded {
        background-color: #eaeaea !important;
    }

    .layout-collapsed {
        background-color: #eaeaea !important;
    }
}

.dark {
    .layout-expanded {
        background-color: rgb(48, 52, 59) !important;
    }

    .layout-collapsed {
        background-color: rgb(48, 52, 59) !important;
    }
}