// Local variables
$font-family: 'Inter', sans-serif;


// Mobile screens.
@media only screen and (max-width: 480px) {
  
    .linkbuilding-overview-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .linkbuilding-overview-general {
            width: '100%';
            margin-bottom: 10px;
        }

        .linkbuidling-overview-cost {
            width: '100%';
            margin-bottom: 10px;
        }
    }
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {

    .linkbuilding-overview-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .linkbuilding-overview-general {
            width: '100%';
            margin-bottom: 10px;
        }

        .linkbuidling-overview-cost {
            width: '100%';
            margin-bottom: 10px;
        }
    }
}


// Laptop screens.
@media only screen and (min-width: 768px) and (max-width: 960px) {
    .linkbuilding-overview-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .linkbuilding-overview-general {
            width: '100%';
            margin-bottom: 10px;
        }

        .linkbuidling-overview-cost {
            width: '100%';
            margin-bottom: 10px;
        }
    }
}

// Desktop screens.
@media only screen and (min-width: 960px) {
    .linkbuilding-overview-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .linkbuilding-overview-general {
            width: 40%;
            margin-bottom: 10px;
        }

        .linkbuidling-overview-cost {
            width: 60%;
            margin-bottom: 10px;
            position: relative;
            margin-left: 10px;
        }
    }
    .linkbuilding-campaign-keywords{
        margin-bottom: 10px; 
    }
    .linkbuilding-campaign-keywords{
        margin-bottom: 10px; 
    }
}

