/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


@import "src/assets/styles/styles";


.admin-sidebar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid $springbok-light-purple;
}

.admin-sidebar-header-logo {
    margin-left: 20px;
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.admin-sidebar-header-text {
    color: #fff;
    font-size: 13px;
    margin-left: 15px;
}
