/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

$font-family: 'Inter', sans-serif;

// Normal table settings
.p-datatable {
    position: relative;
    font-family: $font-family;


    .p-datatable-header {
        background-color: #fafafa;
        color: #3f3f46;
        border: 1px solid #f4f4f5;
        padding: 1.25rem 1.25rem;
        font-weight: 600;
        font-family: $font-family;
    }

    // Table header
    .p-datatable-thead > tr > th {
        padding: 0.75rem 1.5rem;
        border: 1px solid #f4f4f5;
        border-width: 0px 0px 1px 0px;
        font-weight: 500;
        text-align: left;
        color: #6b7280;
        background-color: #fafafa;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }

    // Table rows
    .p-datatable-tbody > tr {
        background: #fff;
        color: #3f3f46;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    }
    
    // Table cells.
    .p-datatable-tbody > tr > td {
        text-align: left;
        border: 1px solid #f4f4f5;
        border-width: 0 0 1px 0;
        padding: 1rem 1.5rem;
    }


 
}

// Small table settings
.p-datatable-sm {

    .p-datatable-header {
        padding: 0.72845rem 0.85rem;
        font-size: 12px;
    }
    .p-datatable-thead > tr > th {
        padding: 0.375rem 0.75rem;
        font-size: 11px;
    }
    .p-datatable-tbody > tr > td {
        padding: 0.5rem 0.75rem;
        font-size: 11px;
    }
    .p-datatable-tfoot > tr > td {
        padding: 0.25rem 0.85rem;
    }
    .p-datatable-footer {
        padding: 0.48535rem 0.85rem;
    }

} 






.p-datatable .p-paginator-top {
    border-width: 1px 1px 0 1px;

}

.p-datatable .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable .p-datatable-footer {
    background: #dedede;
    color: rgba(0, 0, 0, 0.66);
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-width: 0 1px 1px 1px;
    padding: 0.571rem 1rem;
    font-weight: normal;
}


// Table rows
.p-datatable .p-datatable-tfoot>tr>td {
    text-align: left;
    padding: 0.571rem 0.857rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-width: 1px;
    font-weight: 700;
    color: #252525;
    background: #fff;
}



.p-datatable .p-sortable-column .p-sortable-column-icon {
    vertical-align: middle;
    color: #71717A;
    margin-left: 0.5rem;
}


.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #dedede;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #dedede;
}

.p-datatable .p-sortable-column.p-highlight {
    background: rgba(45, 114, 210, .1);
    color: #212529;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #212529;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: #dedede;
    color: #212529;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #212529;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem white;
    outline: 0 none;
}



.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #8888;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
    color: #dedede;
    border-color: transparent;
    background: transparent;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem white;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
    margin-right: 0rem;
}

.p-datatable .p-datatable-tbody>tr:focus {
    outline: 0.15rem solid white;
    outline-offset: 0.15rem;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: #dedede;
    color: #212529;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
    box-shadow: inset 0 2px 0 0 #FFE082;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
    box-shadow: inset 0 -2px 0 0 #FFE082;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
    background: #4c4c4c;
    color: #dedede;
}

.p-datatable .p-column-resizer-helper {
    background: #dedede;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: #191919;
}

.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead,
.p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-tfoot {
    background-color: #9a9a9a;
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable .p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td {
    border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
    background: rgb(246, 246, 246);
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
    background: #fff;
    color: rgba(0, 0, 0, 0.66);
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler {
    color: #212529;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #212529;
}


// Large table settings
.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.07125rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
    padding: 0.71375rem 1.07125rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
    padding: 0.71375rem 1.07125rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
    padding: 0.71375rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 0.71375rem 1.25rem;
}






.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 1px 0 1px 0;
  padding: 1.25rem 1.25rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #fafafa;
  color: #3f3f46;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.75rem 1.5rem;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #6b7280;
  background: #fafafa;
  transition: none;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #3f3f46;
  background: #fafafa;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #71717A;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #312E81;
  background: #EEF2FF;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: #f4f4f5;
  color: #3f3f46;
}
.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #71717A;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #fafafa;
  color: #4F46E5;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4F46E5;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #f4f4f5;
  color: #4F46E5;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #4F46E5;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #6366F1;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #3f3f46;
  transition: none;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #f4f4f5;
  border-width: 0 0 1px 0;
  padding: 1rem 1.5rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #71717A;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: none;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #18181B;
  border-color: transparent;
  background: #f4f4f5;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #6366F1;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
  font-weight: 500;
}
.p-datatable .p-datatable-tbody > tr > td.p-highlight {
  background: #EEF2FF;
  color: #312E81;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #EEF2FF;
  color: #312E81;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #EEF2FF;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #EEF2FF;
}
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #f4f4f5;
  color: #3f3f46;
}
.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
  outline: 0.15rem solid #6366F1;
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:not(.p-highlight):hover {
  background: #f4f4f5;
  color: #3f3f46;
}
.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus {
  outline: 0.15rem solid #6366F1;
  outline-offset: -0.15rem;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #f4f4f5;
  color: #3f3f46;
}
.p-datatable .p-column-resizer-helper {
  background: #4F46E5;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #fafafa;
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: #fafafa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: #fcfcfc;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: #EEF2FF;
  color: #312E81;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
  color: #312E81;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: #312E81;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
  background: #fcfcfc;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.625rem 0.625rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.375rem 0.75rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.75rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.375rem 0.75rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.375rem 0.75rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.5625rem 1.5625rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.9375rem 1.875rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.875rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 0.9375rem 1.875rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 0.9375rem 1.875rem;
}

.p-datatable-drag-selection-helper {
  background: rgba(79, 70, 229, 0.16);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
box-shadow: inset 0 2px 0 0 #4F46E5;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
box-shadow: inset 0 -2px 0 0 #4F46E5;
}