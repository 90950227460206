.p-dialog {
  .heading {
    padding: 0.5rem 0;

    .title {
      display: flex;
      align-items: center;
      gap: 1rem;

      .icon-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: rgb(224, 224, 224);
        color: rgba(8, 8, 8, 0.596);
        font-weight: 700;
        font-size: 1.1rem;
      }
    }

    .description {
      margin-top: 0.25rem;
      color: rgba(0, 0, 0, 0.66);
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .field-group-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }

    .input-field {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 1rem;
      width: 100%;

      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #2196f3;

        &::before {
          background: white;
        }
      }

      & > label {
        font-weight: 700;
        color: rgba(0, 0, 0, 0.66);
      }

      & > input {
        width: 100%;
        padding: 0.5rem 0.75rem;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 0.25rem;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.66);
        background: white;
      }
    }

    .roles-selector {
      &__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0.5rem 0;
        width: 100%;
      }

      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.66);

        & > label {
          display: flex;
          cursor: pointer;
          user-select: none;
        }

        .form-control {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          user-select: none;
        }

        // fancy checkbox
        input[type="checkbox"] {
          appearance: none;
          background-color: transparent;
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 1.15em;
          height: 1.15em;
          border: 0.15em solid currentColor;
          border-radius: 0.15em;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;
        }

        input[type="checkbox"]::before {
          content: "";
          width: 0.65em;
          height: 0.65em;
          transform: scale(0);
          box-shadow: inset 1em 1em #137cbd;
          clip-path: polygon(
                          14% 44%,
                          0 65%,
                          50% 100%,
                          100% 16%,
                          80% 0%,
                          43% 62%
          );
        }

        input[type="checkbox"]:checked::before {
          transform: scale(1);
        }

        .form-control + .form-control {
          margin-top: 1em;
        }
      }
    }
  }
}