
// Mobile screens.
@media only screen and (max-width: 480px) {
  
    .linkplacement-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .linkplacement-general {
            width: '100%';
            margin-bottom: 10px;
        }

        .linkbuidling-acties {
            width: '100%';
            margin-bottom: 10px;
        }
    }
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {

    .linkplacement-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .linkplacement-general {
            width: '100%';
            margin-bottom: 10px;
        }

        .linkbuidling-acties {
            width: '100%';
            margin-bottom: 10px;
        }
    }
}


// Laptop screens.
@media only screen and (min-width: 768px) and (max-width: 960px) {
    .linkplacement-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .linkplacement-general {
            width: '100%';
            margin-bottom: 10px;
        }

        .linkbuidling-acties {
            width: '100%';
            margin-bottom: 10px;
        }
    }
}

// Desktop screens.
@media only screen and (min-width: 960px) {
    .linkplacement-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .linkplacement-general {
            width: 50%;
            margin-bottom: 10px;
        }

        .linkbuidling-acties {
            width: 50%;
            margin-bottom: 10px;
            position: relative;
            margin-left: 10px;
        }
    }
}

.email-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 1rem 0;

    .input-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        label {
            font-weight: 500;
        }

        input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 100%;
        }
    }
}

.email-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    button {
        padding: 0.5rem 1rem;
        border: none;
        cursor: pointer;
        color: white;

        &:disabled {
            background-color: #6c757d;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background-color: #0056b3;
        }
    }
}
