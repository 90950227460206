.light {
    .p-paginator {
        background: #fff;
        color: #323232;
        border: 1px solid rgba(0, 0, 0, .12);
        padding: 0;
        border-radius: 3px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #323232;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 0;
    }
    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background: #e0e0e0;
        border-color: transparent;
        color: #323232;
    }
    .p-paginator .p-paginator-first {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .p-paginator .p-paginator-last {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .p-paginator .p-dropdown {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 2.357rem;
    }
    .p-paginator .p-dropdown .p-dropdown-label {
        padding-right: 0;
    }
    .p-paginator .p-paginator-page-input {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .p-paginator .p-paginator-page-input .p-inputtext {
        max-width: 2.357rem;
    }
    .p-paginator .p-paginator-current {
        background-color: transparent;
        border: 0 none;
        color: #323232;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0;
        padding: 0 0.5rem;
    }
    .p-paginator .p-paginator-pages .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #323232;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 0;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #e0e0e0;
        border-color: rgba(0, 0, 0, 0.12);
        color: #323232;
    }
    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background: #e0e0e0;
        border-color: transparent;
        color: #323232;
    }

}

.dark {
    .p-paginator {
        background: #252525;
        color: #dedede;
        border: solid #191919;
        border-width: 1px;
        padding: 0;
        border-radius: 3px;
    }
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        background-color: transparent;
        border: 0 none;
        color: #dedede;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 0;
    }
    .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background: #e0e0e0;
        border-color: transparent;
        color: #4c4c4c;
    }
    .p-paginator .p-paginator-first {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .p-paginator .p-paginator-last {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .p-paginator .p-dropdown {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 2.357rem;
    }
    .p-paginator .p-dropdown .p-dropdown-label {
        padding-right: 0;
    }
    .p-paginator .p-paginator-page-input {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .p-paginator .p-paginator-page-input .p-inputtext {
        max-width: 2.357rem;
    }
    .p-paginator .p-paginator-current {
        background-color: transparent;
        border: 0 none;
        color: #dedede;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0;
        padding: 0 0.5rem;
    }
    .p-paginator .p-paginator-pages .p-paginator-page {
        background-color: transparent;
        border: 0 none;
        color: #dedede;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0;
        transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 0;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #FFE082;
        border-color: #FFE082;
        color: #212529;
    }
    .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background: #e0e0e0;
        border-color: transparent;
        color: #4c4c4c;
    }

}
