/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Local variables
$font-family: 'Inter', sans-serif;


// Mobile screens.
@media only screen and (max-width: 480px) {
    
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {

}


// Desktop screens.
@media only screen and (min-width: 769px) {

}

.advantiv-navbar-text-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    width: 100px;
    background-color: #f6fff6;
    border-right: 1px solid rgba(128, 128, 128, 0.5);
}

.advantiv-navbar-text {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}
