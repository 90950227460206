//.light {

    .p-message {
        margin: 1rem 0;
        border-radius: 6px;

        .p-message-wrapper {
            padding: 0.5rem;
        }

        .p-message-close {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: transparent;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:hover {
                background-color: rgba(180, 180, 180, 0.6);;
                cursor: pointer;
            }

            &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0;
            }

            .p-message-close-icon {
                color: #1c2127;
            }
        }

        .p-message-text {
            font-size: 0.9rem;
            font-weight: normal;
        }

        .p-message-icon {
            font-size: 1.1rem;
            margin-right: 0.6rem;
        }

        .p-message-summary {
            font-weight: 600;
            font-size: 1.1rem;
        }

        .p-message-detail {
            font-size: 0.8rem;
            margin-left: 0.5rem;
        }
    }

    .p-message-success {
        background-color: rgba(35,133,81,.1);
        border: none;

        .p-message-icon {
            color: #1c6e42;
        }

        .p-message-summary {
            color: #1c6e42;
        }

        .p-message-detail {
            color: #1c2127;
        }
    }

    .p-message-info {
        background-color: rgba(45,114,210,.1);
        border: none;

        .p-message-icon {
            color: #106ba3;
        }

        .p-message-summary {
            color: #106ba3;
        }

        .p-message-detail {
            color: #1c2127;
        }
    }

    .p-message-warn {
        background-color: rgba(200,118,25,.1);
        border: none;

        .p-message-icon {
            color: #935610;
        }

        .p-message-summary {
            color: #935610;
        }

        .p-message-detail {
            color: #1c2127;
        }
    }

    .p-message-error {
        background: rgba(205,66,70,.1);
        border: none;

        .p-message-icon {
            color: #ac2f33;
        }

        .p-message-summary {
            color: #ac2f33;
        }

        .p-message-detail {
            color: #1c2127;
        }
    }

    .p-inline-message {
        padding: 0.5rem;
        margin: 0;
        border-radius: 3px;

        .p-inline-message-icon {
            font-size: 1rem;
            margin-right: 0.5rem;
        }

        .p-inline-message-text {
            font-size: 1rem;
        }

        .p-inline-message-icon-only .p-inline-message-icon {
            margin-right: 0;
        }

        .p-inline-message-success {
            background: rgba(35,133,81,.1);
            border: none;
            color: #1c2127;

            .p-inline-message-icon {
                color: #1c6e42;
            }
        }

        .p-inline-message-info {
            background: rgba(45,114,210,.1);
            border: none;
            color: #106ba3;

            .p-inline-message-icon {
                color: #212121;
            }
        }

        .p-inline-message-warn {
            background: rgba(200,118,25,.1);
            border: none;
            color: #1c2127;

            .p-inline-message-icon {
                color: #935610;
            }
        }

        .p-inline-message-error {
            background: rgba(205,66,70,.1);
            border: none;
            color: #1c2127;

            .p-inline-message-icon {
                color: #ac2f33;
            }
        }
    }
//}



.dark {
    .p-message {
        margin: 1rem 0;
        border-radius: 6px;

        .p-message-wrapper {
            padding: 0.5rem;
        }

        .p-message-close {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: transparent;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:hover {
                background-color: rgba(180, 180, 180, 0.6);;
                cursor: pointer;
            }

            &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0;
            }

            .p-message-close-icon {
                color: #fff;
            }
        }

        .p-message-text {
            font-size: 0.9rem;
            font-weight: normal;
        }
        .p-message-icon {
            font-size: 1.1rem;
            margin-right: 0.6rem;
        }
        .p-message-summary {
            font-weight: 600;
            font-size: 1.1rem;
        }
        .p-message-detail {
            font-size: 0.8rem;
            margin-left: 0.5rem;
        }
    }

    .p-message-success {
        background-color: rgba(35,133,81,.2) !important;;
        border: none !important;

        .p-message-icon {
            color: #72ca9b !important;
        }

        .p-message-summary {
            color: #72ca9b !important;
        }

        .p-message-detail {
            color: rgb(246, 247, 249) !important;
        }
    }

    .p-message-info {
        background-color: rgba(45,114,210,.2) !important;
        border: none !important;

        .p-message-icon {
            color: #8abbff !important;
        }

        .p-message-summary {
            color: #8abbff !important;
        }

        .p-message-detail {
            color: rgb(246, 247, 249) !important;
        }
    }

    .p-message-warn {
        background-color: rgba(200,118,25,.2) !important;
        border: none !important;

        .p-message-icon {
            color: #fbb360 !important;
        }

        .p-message-summary {
            color: #fbb360 !important;
        }

        .p-message-detail {
            color: rgb(246, 247, 249);
        }
    }

    .p-message-error {
        background: rgba(205,66,70,.2) !important;
        border: none !important;

        .p-message-icon {
            color: #fa999c !important;
        }

        .p-message-summary {
            color: #fa999c !important;
        }

        .p-message-detail {
            color: rgb(246, 247, 249);
        }
    }


    .p-inline-message {
        padding: 0.5rem;
        margin: 0;
        border-radius: 3px;
    }
    .p-inline-message.p-inline-message-info {
        background: rgba(45,114,210,.2);
        border: 0 none;
        border-width: 0;
        color: #fff;
    }
    .p-inline-message.p-inline-message-info .p-inline-message-icon {
        color: #8abbff;
    }
    .p-inline-message.p-inline-message-success {
        background: rgba(35,133,81,.2);
        border: 0 none;
        border-width: 0;
        color: rgb(246, 247, 249);
    }
    .p-inline-message.p-inline-message-success .p-inline-message-icon {
        color: #72ca9b;
    }
    .p-inline-message.p-inline-message-warn {
        background: rgba(200,118,25,.2);
        border: 0 none;
        border-width: 0;
        color: rgb(246, 247, 249);
    }
    .p-inline-message.p-inline-message-warn .p-inline-message-icon {
        color: #fbb360;
    }
    .p-inline-message.p-inline-message-error {
        background: rgba(205,66,70,.2);
        border: 0 none;
        border-width: 0;
        color: rgb(246, 247, 249);
    }
    .p-inline-message.p-inline-message-error .p-inline-message-icon {
        color: #fa999c;
    }
    .p-inline-message .p-inline-message-icon {
        font-size: 1rem;
        margin-right: 0.5rem;
    }
    .p-inline-message .p-inline-message-text {
        font-size: 1rem;
    }
    .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
        margin-right: 0;
    }
}

