/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Variables for the project select list item.
$font-family: 'Inter', sans-serif;

// mobile screens.
@media only screen and (max-width: 480px) {
    .project-select-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 50px;
        padding-top: 6px;
        padding-left: 5px;
        padding-bottom: 6px;
        border-bottom: 1px solid darken(lightgray, 10%);
    
        &:nth-child(0) {
            margin-top: 25px;
            border-top: 1px solid darken(lightgray, 10%);
        }
    
        &:hover {
            background-color: lightgray;
        }
    
        .project-select-list-item-icon {
            padding: 10px;
            background-color: rgb(1, 127, 141);
            color: rgb(228, 227, 227);
        }
        
        .project-select-list-item-text-container {
            display: flex;
            flex-direction: column;
        
            .project-select-list-item-name {
                margin-left: 10px;
                font-size: 13px;
                font-weight: 400;
                text-decoration: none;
                color: #3367d6;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            
            .project-select-list-item-id {
                margin-left: 10px;
                margin-top: 7px;
                font-size: 10px;
                font-weight: 400;
                color: rgb(127, 130, 133);
            }
        }
    }
    
}

// tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .project-select-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 50px;
        padding-top: 6px;
        padding-left: 5px;
        padding-bottom: 6px;
        border-bottom: 1px solid darken(lightgray, 10%);
    
        &:nth-child(0) {
            margin-top: 25px;
            border-top: 1px solid darken(lightgray, 10%);
        }
    
        &:hover {
            background-color: lightgray;
        }
    
        .project-select-list-item-icon {
            padding: 10px;
            background-color: rgb(1, 127, 141);
            color: rgb(228, 227, 227);
        }
        
        .project-select-list-item-text-container {
            display: flex;
            flex-direction: column;
        
            .project-select-list-item-name {
                margin-left: 10px;
                font-size: 13px;
                font-weight: 400;
                text-decoration: none;
                color: #3367d6;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            
            .project-select-list-item-id {
                margin-left: 10px;
                margin-top: 7px;
                font-size: 10px;
                font-weight: 400;
                color: rgb(127, 130, 133);
            }
        }
    }
    
}

// desktop screens.
@media only screen and (min-width: 769px) {
    .project-select-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        height: 50px;
        padding-top: 6px;
        padding-left: 5px;
        padding-bottom: 6px;
        border-bottom: 1px solid darken(lightgray, 10%);
    
        &:nth-child(0) {
            margin-top: 25px;
            border-top: 1px solid darken(lightgray, 10%);
        }
    
        &:hover {
            background-color: lightgray;
        }
    
        .project-select-list-item-icon {
            padding: 10px;
            background-color: rgb(1, 127, 141);
            color: rgb(228, 227, 227);
        }
        
        .project-select-list-item-text-container {
            display: flex;
            flex-direction: column;
        
            .project-select-list-item-name {
                margin-left: 10px;
                font-size: 13px;
                font-weight: 400;
                font-family: $font-family;
                text-decoration: none;
                color: #0821e4;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            
            .project-select-list-item-id {
                margin-left: 10px;
                margin-top: 7px;
                color: rgb(127, 130, 133);

                font-family: $font-family;
                font-size: 10px;
                font-weight: 400;
                
            }
        }
    }    
}

