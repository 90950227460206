@import "src/assets/styles/styles";

.layout-sidebar {
    background-color: $springbok-black;
}

.sidebar-collapsed {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;
    z-index: 1000;
}

.sidebar-expanded {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 220px;
    z-index: 1000;
} 


.sidebar-menu {
    position: relative;
    width: 100%;
    border-top: 1px solid $springbok-light-brown;
    border-bottom: 1px solid $springbok-light-brown;
    max-height: 580px;
    overflow-y: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.sidebar-footer {
    margin-top: auto;
    border-top: 1px solid $springbok-light-brown;
}
