
.budgetOverview a .p-accordion-header-text{
    width: 100%;
    table td{
        width: calc(100%/6);
    }
}
table.budgetOverview{
    width: calc(100% - 32px);
    margin-left: 32px;
    tr{
        th{
            width: calc(100%/6);
            text-align: left;
        }
    }
}
