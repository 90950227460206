/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Variables for the project select header.
$font-family: 'Inter', sans-serif;


// mobile screens.
@media only screen and (max-width: 480px) {
    .project-select-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    
    .project-select-header-title {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .project-select-header-search-container {
        margin-top: 20px;
    }
    
    .project-select-header-search {
        width: 100%;
        height: 35px !important;

        font-size: 13px !important;
        font-weight: 500 !important;
        font-family: $font-family !important;
        border-radius: 2px !important;
        border: 1px solid #8f8f8f4f !important;

        transition: all 0.2s ease;

        &:focus {
            box-shadow: 0px 0px 0px 0.15rem #68c4fc9e !important;
        }
    }
}

// tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .project-select-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    
    .project-select-header-title {
        margin: 10px 0 0 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }
    
    .project-select-header-search-container {
        margin-top: 25px;
    }

    .project-select-header-search {
        width: 100%;
        height: 35px !important;

        font-size: 13px !important;
        font-weight: 500 !important;
        font-family: $font-family !important;
        border-radius: 2px !important;
        border: 1px solid #8f8f8f4f !important;

        transition: all 0.2s ease;

        &:focus {
            box-shadow: 0px 0px 0px 0.15rem #68c4fc9e !important;
        }
    }
}

// desktop screens.
@media screen and (min-width: 769px) {
    .project-select-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;


    }
    
    .project-select-header-title {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        font-family: $font-family;
        text-align: center;
    }

    .project-select-header-search-container {
        margin-top: 20px;
    }

    .project-select-header-search {
        width: 100%;
        height: 35px !important;

        font-size: 13px !important;
        font-weight: 500 !important;
        font-family: $font-family !important;
        border-radius: 2px !important;
        border: 1px solid #8f8f8f4f !important;

        transition: all 0.2s ease;

        &:focus {
            box-shadow: 0px 0px 0px 0.15rem #68c4fc9e !important;
        }
    }
}



