/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Local variables
$font-family: 'Inter', sans-serif;


// Mobile screens.
@media only screen and (max-width: 480px) {
    .advantiv-chart-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-height: 500px;
        margin-bottom: 10px;
        
    }
    
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .advantiv-chart-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 400px;
        max-height: 500px;
        margin-bottom: 10px;
    }
}


// Laptop screens.
@media only screen and (min-width: 769px) {
    .advantiv-chart-card {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        min-height: 500px;
        max-height: 500px;
        
        margin-bottom: 10px;

        

        .p-card-body {
            width: 100%;
            height: 100%;
        }
    }
}

// Desktop screens.
@media only screen and (min-width: 960px) {
    .advantiv-chart-card {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 50%;

        min-height: 500px;
        max-height: 500px;
       
        &:not(:first-child) {
            margin-left: 10px;
        }
       

        .p-card-body {
            width: 100%;
            height: 100%;
        }
    }
}

