/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Local variables
$font-family: 'Inter', sans-serif;


// Mobile screens.
@media only screen and (max-width: 480px) {
  
    .advantiv-overview-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .advantiv-overview-card {
            width: '100%';
            margin-bottom: 10px;
        }

        .advantiv-number-cards {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;


            .advantiv-number-card {
                width: 100%;
                height: 125px;
                margin-bottom: 10px;
            }
        }

        .advantiv-cost-table-card {
            width: '100%';
            margin-bottom: 10px;
        }
    }

    .advantiv-chart-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {

    .advantiv-overview-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .advantiv-overview-card {
            width: '100%';
            margin-bottom: 10px;
        }

        .advantiv-number-cards {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;


            .advantiv-number-card {
                width: 100%;
                height: 125px;
                margin-bottom: 10px;
            }
        }

        .advantiv-cost-table-card {
            width: '100%';
            margin-bottom: 10px;
        }
    }

    .advantiv-chart-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}


// Laptop screens.
@media only screen and (min-width: 768px) and (max-width: 960px) {
    .advantiv-overview-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        .advantiv-overview-card {
            width: '100%';
            margin-bottom: 10px;
        }

        .advantiv-number-cards {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;


            .advantiv-number-card {
                width: 33%;
                height: 125px;
                margin-bottom: 10px;
                
                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }

        .advantiv-cost-table-card {
            width: '100%';
            margin-bottom: 10px;
        }
    }


    .advantiv-chart-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

}

// Desktop screens.
@media only screen and (min-width: 960px) {
    .advantiv-overview-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .advantiv-overview-card {
            width: 40%;
            margin-bottom: 10px;
        }

        .advantiv-number-cards {
            display: flex;
            flex-direction: column;
            width: 20%;
            padding-right: 10px;

            .advantiv-number-card {
                position: relative;
                width: 100%;
                height: 33%;
                min-height: 125px;
                margin-bottom: 10px;
                margin-left: 10px;
            }
        }

        .advantiv-cost-table-card {
            width: 40%;
            margin-bottom: 10px;
            position: relative;
            margin-left: 10px;
        }
    }

    .advantiv-chart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
}

