.p-accordion .p-accordion-header .p-accordion-header-link{
    background-color: lightgray !important;
    color: #000 !important;
}
.p-accordion-content{
    background-color: #fff !important;
    color: darkgray !important;
}
.p-accordion .p-accordion-header:hover .p-accordion-header-link{
    background-color: #fafafa !important;
    border: 1px solid black !important;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
    background-color: rgb(121, 121, 121) !important;
    border: 1px solid black !important;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background-color: #fafafa !important;
    border: 1px solid black !important;
}