.top-bar {
  .description {
    max-width: 40rem;
  }
}

.content-container {
  display: grid;
  gap: 2rem;
  margin-top: 2rem;

  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    > .card {
      padding: 3rem 2rem;
      border: 1px solid rgba(0, 0, 0, 0.12);
      display: flex;
      gap: 2rem;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      border-radius: 12px;
      box-shadow: 2px 2px 7.5px rgba(0, 0, 0, 0.07);

      &:hover {
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

        .redirect-icon {
          right: 0.75rem;
          top: 0.75rem;
        }
      }
      &:active {
        box-shadow: inset 2px 2px 7.5px rgba(0, 0, 0, 0.1);

        .redirect-icon {
          right: 0.75rem;
          top: 0.75rem;
        }
      }

      h3 {
        text-decoration: none;
      }

      .card-icon {
        font-size: 1.25rem;
        color: #636363;
      }

      .redirect-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 1.25rem;
        color: #636363;
        cursor: pointer;
        user-select: none;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}