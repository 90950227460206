/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// README: This is the custom theme file for the menubar component of prime react.
// This affects the following components:
//      - p-menubar
//      - p-menu-list
//      - p-menuitem
//      - p-menuitem-text
//      - p-menuitem-icon
//      - p-submenu-icon


// Local variables.
$font-family: 'Inter', sans-serif;

$menu-bar-text-active-color: #0078d4;
$menu-bar-text-inactive-color: #323130;

$menu-bar-icon-active-color: #0078d4;
$menu-bar-icon-inactive-color: #323130;


// This is the menu bar on large screens.
.p-menubar {
    background: none;
    z-index: 1;
    border: none;
    font-family: $font-family;
    font-size: 14px;

    .p-menuitem-link {
        padding: 0.65rem 0.5rem;
        color: $menu-bar-text-inactive-color;
        border-radius: 0;
        transition: box-shadow 0.2s ease 0s;
        user-select: none;

        &:not(.p-disabled):hover {
            color: $menu-bar-text-active-color;

            .p-menuitem-text {
                color: $menu-bar-text-active-color;
            }

            .p-menuitem-icon {
                color: $menu-bar-icon-active-color;
            }

            .p-submenu-icon {
                color: $menu-bar-icon-active-color;
            }
        }

        &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem #605e5c;
        }

        .p-menuitem-text {
            font-family: $font-family;
            font-size: 14px;
            color: $menu-bar-text-inactive-color;
        }

        .p-menuitem-icon {
            font-size: 14px;
            color: $menu-bar-icon-inactive-color;
        }

        .p-submenu-icon {
            font-size: 14px;
            color: $menu-bar-icon-inactive-color;
        }
    }

    .p-submenu-list {
        display: none;
        position: absolute;
        z-index: 1;
        padding: 0;
        background: rgb(255, 255, 255);
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        width: 12.5rem;

        .p-menu-separator {
            border-top: 1px solid #edebe9;
            margin: 0.25rem 0;
        }

        .p-submenu-icon {
            font-size: 0.875rem;
            color: $menu-bar-icon-inactive-color;
        }
    }

    .p-menuitem {
        .p-menuitem-active {
            > .p-menuitem-link {
                .p-menuitem-text {
                    color: $menu-bar-text-active-color;
                }
                .p-menuitem-icon {
                    color: $menu-bar-icon-active-color;
                }
                .p-submenu-icon {
                    color: $menu-bar-icon-active-color;
                }
            }
        }
    }

    .p-menubar-root-list {
        > .p-menuitem {
            > .p-menuitem-link {
                padding: 0.75rem 0.5rem;
                color: $menu-bar-text-inactive-color;
                border-radius: 2px;
                transition: box-shadow 0.2s;
                user-select: none;

                .p-menuitem-text {
                    font-family: $font-family;
                    color: $menu-bar-text-inactive-color;
                }

                .p-menuitem-icon {
                    margin-right: 0.5rem;
                    color: $menu-bar-icon-inactive-color;
                }

                .p-submenu-icon {
                    margin-left: 0.5rem;
                    color: $menu-bar-icon-inactive-color;
                }

                &:focus {
                    outline: 0 none;
                    outline-offset: 0;
                    box-shadow: inset 0 0 0 0.15rem lighten($menu-bar-icon-active-color, 50%);

                    .p-menuitem-text {
                        font-family: $font-family;
                        color: $menu-bar-text-active-color;
                    }
    
                    .p-menuitem-icon {
                        margin-right: 0.5rem;
                        color: $menu-bar-icon-active-color;
                    }
    
                    .p-submenu-icon {
                        margin-left: 0.5rem;
                        color: $menu-bar-icon-active-color;
                    }
                }

                &:not(.p-disabled) {
                    &:hover {
                        .p-menuitem-text {
                            color: $menu-bar-text-active-color;
                        }

                        .p-menuitem-icon {
                            color: $menu-bar-icon-active-color;
                        }

                        .p-submenu-icon {
                            color: $menu-bar-icon-active-color;
                        }
                    }
                }
            }

            > .p-menuitem-active {
                > .p-menuitem-link {
                    .p-menuitem-text {
                        color: $menu-bar-text-active-color;
                    }
                    .p-menuitem-icon {
                        color: $menu-bar-icon-active-color;
                    }
                    .p-submenu-icon {
                        color: $menu-bar-icon-active-color;
                    }

                    &:not(.p-disabled) {
                        &:hover {
                            .p-menuitem-text {
                                color: $menu-bar-text-active-color;
                            }
                            .p-menuitem-icon {
                                color: $menu-bar-icon-active-color;
                            }
                            .p-submenu-icon {
                                color: $menu-bar-icon-active-color;
                            }
                        }
                    }
                }
            }

        }
    }
}

// This is the menu bar on small screens.
@media screen and (max-width: 960px) {
    .p-menubar {
        display: flex;
        align-items: center;
        position: relative;

        // The menubar button (Hamburger menu).
        .p-menubar-button {
            display: flex;
            width: 2rem;
            height: 2rem;
            color: $menu-bar-text-inactive-color;
            border-radius: 2px;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    
            &:hover {
                color: $menu-bar-text-active-color;
                background: none;
            }
    
            &:focus {
                color: $menu-bar-text-active-color;
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem lighten($menu-bar-icon-active-color, 25%);
            }
        }

        .p-menuitem-link {
            &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem lighten($menu-bar-text-active-color, 25%);

                .p-menuitem-text {
                    color: $menu-bar-text-active-color;
                }
                .p-menuitem-icon {
                    color: $menu-bar-icon-active-color;
                }
                .p-submenu-icon {
                    color: $menu-bar-icon-active-color;
                }
            }
        }

        .p-menubar-root-list {
            display: none;
            position: absolute;
            padding: 0;
            border: 0 none;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            background: rgb(255, 255, 255);
            width: 12.5rem;

            .p-menu-separator {
                margin: 0.25rem 0;
            }

            .p-submenu-icon {
                font-size: 0.875rem;
            }

            > .p-menuitem {
                position: relative;

                > .p-menuitem-link {
                    padding: 0.75rem 0.5rem;
                    color: #323130;
                    border-radius: 0;
                    transition: box-shadow 0.2s;
                    user-select: none;
    
                    .p-menuitem-text {
                        color: $menu-bar-text-inactive-color;
                    }

                    .p-menuitem-icon {
                        color: $menu-bar-icon-inactive-color;
                        margin-right: 0.5rem;
                    }

                    .p-submenu-icon {
                        color: $menu-bar-icon-inactive-color;
                    }
                }
            }
        }
    }


    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: $menu-bar-text-active-color;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: $menu-bar-icon-active-color;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: $menu-bar-icon-active-color;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem $menu-bar-text-active-color;
    }



    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
        margin-left: auto;
        transition: transform 0.2s;
    }

    .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        position: static;
        box-shadow: none;
        border: 0 none;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.2s;
        transform: rotate(90deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
        transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
    }

    .p-menubar .p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}