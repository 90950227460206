/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Variables for the project select dialog.
$font-family: 'Inter', sans-serif;


// mobile screens.
@media only screen and (max-width: 480px) {
    .project-select-dialog-container {
        display: flex;
        flex-direction: column;
        min-width: 100vw;
        max-width: 100vw;
    
        .project-select-dialog-list {
            overflow-y: scroll;
            height: 350px;
        }
    }
}

// tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .project-select-dialog-container {
        display: flex;
        flex-direction: column;
        width: 350px;

        .project-select-dialog-list {
            overflow-y: scroll;
            height: 350px;
        }
    }
}

// desktop screens.
@media only screen and (min-width: 769px) {
    .project-select-dialog-container {
        display: flex;
        flex-direction: column;
        width: 500px;
    
    
        .project-select-dialog-list {
            overflow-y: scroll;
            height: 350px; // Each item is 50px high, so 8 items will fit in the list + the height of the tabview.
        }
    }
}


.project-select-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;

    .project-select-loading-text {
        margin-top: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        font-family: $font-family;
    }
}

.golden-badge {
    background-color: #de8540; /* caramel-like color */
    border-radius: 50%;
    padding: 18px;
    box-shadow: 0 0 4px 0px #281f04ca; /* caramel-like color */
    display: inline-block;
    color: #fff;
    font-size: 34px !important;

}

.project-select-welcome-text {
    font-size: 14px;
    font-weight: 400;
    font-family: $font-family;
    margin-top: 30px;
    text-align: center;
    line-height: 1.3;
}
