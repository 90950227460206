.p-dropdown {
    background: #ffffff;
    color: black !important;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #ced4da;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border-color: #007bff;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #979998;
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: black;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.p-dropdown .p-dropdown-clear-icon {
    color: black;
    right: 2.357rem;
}

.p-dropdown.p-invalid.p-component {
    border-color: #dc3545;
}

.p-dropdown-panel {
    background: #ffffff;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: none;
}

.p-dropdown-panel .p-dropdown-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #dee2e6;
    color: black;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: black;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
    padding-right: 3.5rem;
    margin-right: -3.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
    right: 2.5rem;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1.5rem;
    border: 0 none;
    color: black;
    background: transparent;
    transition: box-shadow 0.15s;
    border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #ffffff;
    background: #007bff;
}

.p-dropdown-label.p-inputtext {
    color: black;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: black;
    background: #e9ecef;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: black;
    background: #ffffff;
    font-weight: 600;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1.5rem;
    color: #212529;
    background: transparent;
}