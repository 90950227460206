/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


@import "src/assets/styles/styles";


.admin-layout-sidebar {
    background-color: $springbok-dark-blue;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 220px;
    z-index: 1000;
}

.admin-sidebar-menu {
    position: relative;
    width: 100%;
    border-top: 1px solid $springbok-dark-blue;
    border-bottom: 1px solid $springbok-dark-blue;
    max-height: 580px;
    overflow-y: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.admin-sidebar-footer {
    margin-top: auto;
    border-top: 1px solid $springbok-light-purple;
}
