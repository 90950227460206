input:disabled {
    background: rgba(64,72,84,.5);
    box-shadow: none;
    color: rgba(171,179,191,.6);
}

.p-component.p-inputtext {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #131313;
    font-size: 12px;
    background: #fff;
    padding: 0.45rem 0.45rem;
    border: none;
    // box-shadow: 0 0 0 0 rgb(45 114 210 / 0%), 0 0 0 0 rgb(45 114 210 / 0%), inset 0 0 0 1px rgb(17 20 24 / 20%), inset 0 1px 1px rgb(17 20 24 / 50%);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 2px;
    height: 30px;

    &:disabled {
        background: rgba(64,72,84,.5) !important;
        box-shadow: none !important;
        color: rgba(171,179,191,.6) !important;
        cursor: not-allowed;
    }
}

.p-component.p-inputtext.p-inputtext-sm {
    font-size: 0.7rem;
    padding: 0.3rem 0.3rem;
}
.p-component.p-inputtext.p-inputtext-lg {
    font-size: 1.1rem;
    padding: 0.45rem 0.45rem;
}
.p-component.p-inputtext:enabled:hover {
    border: none;
}
.p-component.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    // box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px rgb(45 114 210 / 30%), inset 0 1px 1px rgb(17 20 24 / 20%);
    border: none;
}
.p-component.p-inputtext.p-invalid {
    // box-shadow: 0 0 0 0 rgb(231 106 110 / 0%), 0 0 0 0 rgb(231 106 110 / 0%), 0 0 0 0 rgb(231 106 110 / 0%), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px hsl(0deg 0% 100% / 20%), inset 0 -1px 1px 0 #8f99a8;
}

