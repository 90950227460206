// Primary button //
.p-button {
  // box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 10%), 0 1px 2px rgb(17 20 24 / 40%);
  color: #FFF;
  background: #137cbd;
  border: none;
  padding: 0.429rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;

  &:hover {
    background: #106ba3;
    color: #FFF;
    border: none;
  }
  &:active {
    background: #184a90;
    color: #FFF;
    border: none;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    // box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 10%), 0 1px 2px rgb(17 20 24 / 40%);
  }
  &:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem #e9edf8;
    border: 1px solid hsla(0deg, 0%, 100%, 0.4);
  }
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #8abbff;
  border: 1px solid rgba(138,187,255,.6);
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 224, 130, 0.04);
  color: #8abbff;
  border: 1px solid rgba(138,187,255,.6);
}
.p-button.p-button-outlined:enabled:focus-visible {
  border: 1px solid hsla(0deg, 0%, 100%, 0.4);
  box-shadow: 0 0 0 0.1rem #e9edf8;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(45,114,210,.2);
  color: #8abbff;
  border: 1px solid rgba(138,187,255,.6);
}
.p-button.p-button-text {
  background-color: transparent;
  color: #8abbff;
  border: none;
  box-shadow: none;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(45,114,210,.2);
  color: #8abbff;
  border: none;
}
.p-button.p-button-text:enabled:active {
  background: rgba(45,114,210,.3);
  color: #99c4ff;
  border: none;
}
.p-button.p-button-text:enabled:focus-visible {
  border: 1px solid hsla(0deg, 0%, 100%, 0.4);
  box-shadow: 0 0 0 0.1rem #e9edf8;
}

// Secondary button //
.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  background: #383e47;
  border: none;
  color: #f6f7f9;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #2f343c;
  border: none;
  color: #f6f7f9;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  // box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 10%), 0 1px 2px rgb(17 20 24 / 40%);
}
.p-button.p-button-secondary:enabled:focus-visible, .p-buttonset.p-button-secondary > .p-button:enabled:focus-visible, .p-splitbutton.p-button-secondary > .p-button:enabled:focus-visible {
  box-shadow: 0 0 0 0.1rem #faefef;
  border: 1px solid hsla(0deg, 0%, 100%, 0.4);
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #1c2127;
  color: #f6f7f9;
  border: none;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #fff;
  border: 1px solid hsla(0,0%, 100%, .4);
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(143,153,168,.15);
  color: #fff;
  border: 1px solid hsla(0,0%,100%,.4)
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(143,153,168,.3);
  color: #fff;
  border: none;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #fff;
  border: none;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(143,153,168,.15);
  border: none;
  color: #fff;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(143,153,168,.3);
  border: none;
  color: #fff;
}

// Success button //
.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  background: #238551;
  border: none;
  color: #fff;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #1c6e42;
  border: none;
  color: #fff;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  // box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 10%), 0 1px 2px rgb(17 20 24 / 40%);
}
.p-button.p-button-success:enabled:focus-visible, .p-buttonset.p-button-success > .p-button:enabled:focus-visible, .p-splitbutton.p-button-success > .p-button:enabled:focus-visible {
  box-shadow: 0 0 0 0.1rem #F1F8E9;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #165a36;
  color: #fff;
  border-color: #8BC34A;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #AED581;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(174, 213, 129, 0.04);
  color: #AED581;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(174, 213, 129, 0.16);
  color: #AED581;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #AED581;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(174, 213, 129, 0.04);
  border-color: transparent;
  color: #AED581;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(174, 213, 129, 0.16);
  border-color: transparent;
  color: #AED581;
}

// Warning button //
.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #212529;
  background: #FFB74D;
  border: 1px solid #FFB74D;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #FFA726;
  color: #212529;
  border-color: #FFA726;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  // box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 10%), 0 1px 2px rgb(17 20 24 / 40%);
}
.p-button.p-button-warning:enabled:focus-visible, .p-buttonset.p-button-warning > .p-button:enabled:focus-visible, .p-splitbutton.p-button-warning > .p-button:enabled:focus-visible {
  box-shadow: 0 0 0 0.1rem #f5efb7;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #FF9800;
  color: #212529;
  border-color: #FF9800;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #FFB74D;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 183, 77, 0.04);
  color: #FFB74D;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 183, 77, 0.16);
  color: #FFB74D;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #FFB74D;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 183, 77, 0.04);
  border-color: transparent;
  color: #FFB74D;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 183, 77, 0.16);
  border-color: transparent;
  color: #FFB74D;
}

// Error button //
.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  background: #cd4246;
  border: none;
  color: #FFF;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #ac2f33;
  border: none;
  color: #FFF;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  // box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 10%), 0 1px 2px rgb(17 20 24 / 40%);
}
.p-button.p-button-danger:enabled:focus-visible, .p-buttonset.p-button-danger > .p-button:enabled:focus-visible, .p-splitbutton.p-button-danger > .p-button:enabled:focus-visible {
  box-shadow: 0 0 0 0.1rem #f8bdc6;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #8e292c;
  border: none;
  color: #FFF;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #E57373;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(229, 115, 115, 0.04);
  color: #E57373;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(229, 115, 115, 0.16);
  color: #E57373;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #E57373;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(229, 115, 115, 0.04);
  border-color: transparent;
  color: #E57373;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(229, 115, 115, 0.16);
  border-color: transparent;
  color: #E57373;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
}

.p-button.p-button-raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.25), 0px 2px 2px 0px rgba(0, 0, 0, 0.15), 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.429rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.375375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1rem;
  padding: 0.53625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

