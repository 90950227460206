// Desktop screens.
@media only screen and (min-width: 960px) {
    .linkbuilding-website-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .linkbuilding-website-info {
            width: 40%;
            margin-bottom: 10px;
        }

        .linkbuilding-website-linkplacements {
            width: 60%;
            position: relative;
            margin-left: 10px;
        }
    }
}


.list-blocks{
    width: 100%;
    div.sub-item{
        display: flex;
        width: 100%;
        margin: 15px 0;
        .sub-item-key{
            min-width: 40%;
            color: "#636c79"
        }
        .sub-item-value{
            margin-left: 'auto';
        }
    }
}