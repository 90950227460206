.light {
    .p-panel .p-panel-header {
        border: 2px solid rgba(0,0,0,0.12);
        padding: 0.857rem 1rem;
        background: #f8f9fa;
        color: #343a40;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .p-panel .p-panel-header .p-panel-title {
        font-weight: 600;
    }

    .p-panel .p-panel-header .p-panel-header-icon {
        width: 2rem;
        height: 2rem;
        color: #8888;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
        color: #dedede;
        border-color: transparent;
        background: transparent;
    }
    .p-panel .p-panel-header .p-panel-header-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }

    .p-panel.p-panel-toggleable .p-panel-header {
        padding: 0.857rem 1rem;
    }

    .p-panel .p-panel-content {
        padding: 0.571rem 1rem;
        border: 2px solid rgba(0,0,0,0.12);
        background: #fff;
        color: #323232;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top: 0 none;
    }

    .p-panel .p-panel-footer {
        padding: 0.571rem 1rem;
        border: 1px solid #191919;
        background: #323232;
        color: #dedede;
        border-top: 0 none;
    }

    .p-panel .p-panel-icons-end {
        order: 2;
        margin-left: auto;
    }

    .p-panel .p-panel-icons-start {
        order: 0;
        margin-right: 0.5rem;
    }

    .p-panel .p-panel-icons-center {
        order: 2;
        width: 100%;
        text-align: center;
    }

}

.dark {
    .p-panel .p-panel-header {
        border: 1px solid #191919;
        padding: 0.857rem 1rem;
        background: #191919;
        color: #dedede;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .p-panel .p-panel-header .p-panel-title {
        font-weight: 700;
    }

    .p-panel .p-panel-header .p-panel-header-icon {
        width: 2rem;
        height: 2rem;
        color: #8888;
        border: 0 none;
        background: transparent;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
        color: #dedede;
        border-color: transparent;
        background: transparent;
    }
    .p-panel .p-panel-header .p-panel-header-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem white;
    }

    .p-panel.p-panel-toggleable .p-panel-header {
        padding: 0.857rem 1rem;
    }

    .p-panel .p-panel-content {
        padding: 0.571rem 1rem;
        border: 1px solid #191919;
        background: #323232;
        color: #dedede;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top: 0 none;
    }

    .p-panel .p-panel-footer {
        padding: 0.571rem 1rem;
        border: 1px solid #191919;
        background: #323232;
        color: #dedede;
        border-top: 0 none;
    }

    .p-panel .p-panel-icons-end {
        order: 2;
        margin-left: auto;
    }

    .p-panel .p-panel-icons-start {
        order: 0;
        margin-right: 0.5rem;
    }

    .p-panel .p-panel-icons-center {
        order: 2;
        width: 100%;
        text-align: center;
    }

}
