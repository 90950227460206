/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


@import "src/assets/styles/styles";



.admin-sidebar-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    width: 100%;
    min-height: 40px;

    padding: 10px 0;

    &:hover {
        cursor: pointer;
        background-color: $springbok-dark-purple;
    }

    .admin-sidebar-menu-item-text {
        margin-left: 15px;
        font-size: 11px !important;
        font-family: $font-family;
        color: #F4F6FA;
    }

    .admin-sidebar-menu-item-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;

        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;
        margin-left: 15px;
        border-radius: 3px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        background-color: #515354;

    }
}

.admin-sidebar-menu-item-active {
    background-color: rgba(#7a7b80, 35%) !important;
    border-left: 2px solid $springbok-light-purple !important;
}



