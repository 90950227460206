@import "src/assets/styles/styles";

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background: #000;
    height: 80px;
    width: 100vw;


    .footer-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 80px;

        img {
            width: 100px;
            height: 28px;
            margin-right: 50px;
        }

        p {
            font-family: "Sharp Grotesk", sans-serif;
            color: white;
        }

        span {
            color: $springbok-light-yellow;
        }
    }

}