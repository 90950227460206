/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

@import "src/assets/styles/styles";


// Local variables
$font-family: 'Inter', sans-serif;


.sidebar-collapsed {
    .sidebar-project-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid $springbok-light-brown;
    
    
        .project-icon {
            padding: 7px;
            background-color: rgb(1, 127, 141);
            color: rgb(228, 227, 227);
            font-size: 11px;
            margin-left: 15px;
            border-radius: 3px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

            &:hover {
                cursor: pointer;
            }
        }
    
    
        .select-project-button-container {
            &:hover {
                cursor: pointer;
            }
        }
    
        .sidebar-project-text {
            margin-left: 15px;
            font-size: 11px;
            font-family: $font-family;
            color: #F4F6FA;
        }
    }
}

.sidebar-expanded {
    .sidebar-project-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid $springbok-light-brown;
    
    
        .project-icon {
            padding: 7px;
            background-color: rgb(1, 127, 141);
            color: rgb(228, 227, 227);
            font-size: 11px;
            margin-left: 15px;
            border-radius: 3px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        }
    
    
        .select-project-button-container {
            &:hover {
                cursor: pointer;
            }
        }
    
        .sidebar-project-text {
            margin-left: 15px;
            font-size: 11px;
            font-family: $font-family;
            color: #F4F6FA;
        }
    }
}

