/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */
 

// Define the primary color and font for the page.
$primary-color: rgb(14, 19, 30);
$secondary-color: rgb(218, 182, 99);
$font-family: 'Inter', sans-serif;


// Mobile screens.
@media only screen and (max-width: 480px) {
    .reset-password-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
        background-color: $primary-color;
    }
    
    .logo {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        width: 80px;
        height: 80px;
    }
    
    .title {
        margin-top: 20px;
        margin-bottom: 40px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        text-transform: uppercase;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        color: #FFF;
    }
    
    .reset-password-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        animation: fadeIn 0.5s ease;

        .reset-password-form-field {
            margin-top: 20px;
            position: relative;
        }
    
        .reset-password-form-field-icon {
            position: absolute;
            top: 75%;
            left: 0;
            transform: translateY(-50%);
            color: #595e6e !important;
        }
    
        .reset-password-form-field-input {
            padding-top: 0;
            padding-left: 30px;
    
            width: 275px;
            height: 35px;
    
            border: none;
            border-radius: 2px;
    
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            transition: background-color 0.2s ease-in-out;
    
            background-color: #e8eaf6;
            font-size: 12px;
            font-weight: 400;
            
            color: #252f3d;
            transition: all 0.2s ease;
    
            &:focus {
                outline: none;
                background-color: #f7f0e0;
            }
    
            &::placeholder {
                color: #8c8c8c;
            }
        }
    }

    .reset-password-form-submit-button {
        margin-top: 20px !important;
        width: 275px;
        height: 35px;

        border-radius: 5px;
        background-color: $secondary-color !important; 
        color: $primary-color !important;

        border: none;
        border-radius: 2px;


        font-family: $font-family;
        font-size: 14px !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        text-align: center;


        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.2s ease;

        user-select: none;

        &:hover {
            cursor: pointer;
            background-color: darken($secondary-color, 15%);
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }

        &:disabled {
            background-color: $secondary-color;
            color: $primary-color;
        }
    }

    .reset-password-form-error {
        margin-top: 20px;
        min-width: 275px !important;
        max-width: 275px !important;

        .p-message-detail {
            font-size: 11px !important;
        }

        .p-message-icon {
            font-size: 18px !important;
        }
    }
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .reset-password-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
        background-color: $primary-color;
    }
    
    .logo {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        width: 100px;
        height: 100px;
    }
    
    .title {
        margin-top: 20px;
        margin-bottom: 40px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        text-transform: uppercase;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        color: #FFF;
    }
    
    .reset-password-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        animation: fadeIn 0.5s ease;

        .reset-password-form-field {
            margin-top: 20px;
            position: relative;
        }
    
        .reset-password-form-field-icon {
            position: absolute;
            top: 75%;
            left: 0;
            transform: translateY(-50%);
            color: #595e6e !important;
        }
    
        .reset-password-form-field-input {
            padding-top: 0;
            padding-left: 30px;
    
            width: 350px;
            height: 40px;
    
            border: none;
            border-radius: 2px;
    
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            transition: background-color 0.2s ease-in-out;
    
            background-color: #e8eaf6;
            font-size: 14px;
            font-weight: 400;
            
            color: #252f3d;
            
            transition: all 0.2s ease;
            &:focus {
                outline: none;
                background-color: #f7f0e0;
            }
    
            &::placeholder {
                color: #8c8c8c;
            }
        }
    }

    .reset-password-form-submit-button {
        margin-top: 20px !important;
        width: 350px;
        height: 40px;

        border-radius: 5px;
        background-color: $secondary-color !important;
        color: $primary-color !important;

        border: none;
        border-radius: 2px;


        font-family: $font-family;
        font-size: 14px !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        text-align: center;


        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.2s ease;

        user-select: none;

        &:hover {
            cursor: pointer;
            background-color: darken($secondary-color, 15%);
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }
    }

    .reset-password-form-error {
        margin-top: 20px;

        min-width: 350px !important;
        max-width: 350px !important;

        .p-message-detail {
            font-size: 12px !important;
        }

        .p-message-icon {
            font-size: 18px !important;
        }
    }
}


// Large screens
@media only screen and (min-width: 769px) {
    .reset-password-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
        background-color: $primary-color;
    }
    
    .logo {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        width: 110px;
        height: 110px;
    }
    
    .title {
        margin-top: 35px;
        margin-bottom: 50px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        text-transform: uppercase;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        color: #FFF;
    }
    
    .reset-password-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        animation: fadeIn 0.5s ease;

        .reset-password-form-field {
            margin-top: 25px;
            position: relative;
        }
    
        .reset-password-form-field-icon {
            position: absolute;
            top: 70%;
            left: 0;
            transform: translateY(-50%);
            color: #595e6e !important;
            font-size: 16px;
        }
    
        .reset-password-form-field-input {
            padding-top: 0;
            padding-left: 30px;
    
            width: 400px;
            height: 40px;
    
            border: none;
            border-radius: 2px;
    
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            transition: background-color 0.2s ease-in-out;
    
            background-color: #e8eaf6;
            font-size: 14px;
            font-weight: 400;
            
            color: #252f3d;
            
            transition: all 0.2s ease;
            
            &:focus {
                outline: none;
                background-color: #f7f0e0;
            }
    
            &::placeholder {
                color: #8c8c8c;
            }
        }
    }

    .reset-password-form-submit-button {
        margin-top: 30px !important;
        width: 400px;
        height: 40px;

        border-radius: 5px;
        background-color: $secondary-color !important;
        color: $primary-color !important;

        border: none;
        border-radius: 2px;


        font-family: $font-family;
        font-size: 14px !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        text-align: center;


        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.2s ease;

        user-select: none;
        &:hover {
            cursor: pointer;
            background-color: darken($secondary-color, 15%);
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
        }
    }

    .reset-password-form-error {
        margin-top: 20px;
        min-width: 400px !important;
        max-width: 400px !important;

        .p-message-detail {
            font-size: 12px !important;
        }
    }
}

.reset-password-container {
    .p-button {
        &:active {
            background-color: darken($secondary-color, 10%) !important;
            color: darken($primary-color, 15%) !important;
            outline: none;
            box-shadow: none;
        }

        &:enabled {
            &:hover {
                background-color: darken($secondary-color, 10%) !important;
                color: darken($primary-color, 15%) !important;
            }
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:focus-visible {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.1rem hsl(46, 100%, 50%);
            border: 1px solid hsl(46, 100%, 50%);
        }
    }
}