/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

@import "src/assets/styles/styles";

.sidebar-collapsed {
    .sidebar-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        min-height: 50px;
        border-bottom: 1px solid $springbok-light-brown;
        
        .collapse-button {
            color: #c5c7c9;
            margin-right: 0px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .sidebar-logo {
        margin-left: 40px;
        width: 14px !important;
        height: 14px !important;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
}

.sidebar-expanded {
    .sidebar-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: 100%;
        min-height: 50px;
        border-bottom: 1px solid $springbok-light-brown;

        .sidebar-logo {
            margin-left: 20px;
            width: 24px;
            height: 24px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
        }
    
        .collapse-button {
            color: #c5c7c9;
            margin-right: 15px;
            margin-left: auto;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

