/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */

@import "src/assets/styles/styles";
 


// Mobile screens.
@media only screen and (max-width: 480px) {
    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
        background-color: $springbok-black;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeIn 0.5s ease;
    
        .login-form-field {
            margin-top: 20px;
            position: relative;
        }

        .login-form-field-input {
            padding-top: 0;
            padding-left: 30px;
            width: 275px;
            height: 35px;
            border: none;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 400;

        }
    }

    .login-form-error {
        margin-top: 20px;
        max-width: 275px !important;

        .p-message-detail {
            font-size: 11px !important;
        }

        .p-message-icon {
            font-size: 18px !important;
        }
    }
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .login-form-field {
            margin-top: 20px;
            position: relative;
        }
    
        .login-form-field-input {
            padding-top: 0;
            padding-left: 30px;
            width: 350px;
            height: 40px;
            border: none;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            transition: all 0.2s ease;
        }

        .login-form-submit-button {
            margin-top: 25px;
            width: 150px;
            height: 40px;
            border: none;
            border-radius: 15px;
            font-size: 14px !important;
            font-weight: 500 !important;
        }
    }

    .login-form-error {
        margin-top: 20px;
        max-width: 350px !important;

        .p-message-detail {
            font-size: 12px !important;
        }

        .p-message-icon {
            font-size: 18px !important;
        }
    }
}


// Large screens
@media only screen and (min-width: 769px) {
    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeIn 0.5s ease;
    
        .login-form-field {
            margin-top: 25px;
            position: relative;
        }
    
        .login-form-field-input {
            padding: 16px 28px;
            width: 400px;
            height: 45px;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 400;
        }
    
        .login-form-submit-button {
            margin-top: 25px;
            width: 150px;
            height: 40px;
            border: none;
            border-radius: 15px;
            font-size: 14px !important;
            font-weight: 500 !important;
        }
    }

    .login-form-error {
        margin-top: 20px;
        max-width: 400px !important;

        .p-message-detail {
            font-size: 12px !important;
        }
    }
}


.login-container {

    background-image: url('../../../../../src/assets/images/circles.svg');
    background-position: center;
    background-size: cover;
    background-color: $springbok-black;

    .title {
        small {
            font-weight: 400;
            font-size: 36px;
            color: #fff;

        }

        text-transform: none;
        text-align: center;
        margin-bottom: 50px;
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        color: $springbok-light-purple;
    }

    .login-form-field-input {
        padding: 16px 28px;
        width: 400px;
        height: 45px;
        border-radius: 10px;

        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.4);
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        transition: all 0.2s ease;

        &:focus {
            outline: none;
            background-color: transparent;
        }

        &::placeholder {
            color: #8c8c8c;
        }
    }

    .login-form-submit-button {
        background-color: $springbok-light-yellow;
        color: $springbok-black;
        font-family: 'Inter', sans-serif;;
        text-transform: uppercase;
        transition: all 0.2s ease;

        &:hover {
            cursor: pointer;
            background-color: darken($springbok-light-yellow, 10%);
            color: $springbok-black;
        }
    }
}