/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// Local variables
$font-family: 'Inter', sans-serif;

// Small Mobile Screens.
@media only screen and (max-width: 340px) {
    .advantiv-welcome-panel {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 210px !important;

        .advantiv-welcome-panel-text-container {
            padding: 10px 10px;
            line-height: 1.3;
        }

        .advantiv-welcome-panel-text {
            font-size: 12px;
            line-height: 1.3;
        }
    }

    .advantiv-homepage-cards {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        .advantiv-homepage-card-container {
            padding: 15px;
            width: 100%;
            height: 120px;
            border: 1px solid #bcbcbc84;
            margin-top: 10px;
    
            .advantiv-homepage-card {
                display: flex;
                flex-direction: row;
            }
    
            .advantiv-homepage-card-arrow {
                margin-left: auto;
                color: #4baf23;
            }
    
            .advantiv-homepage-card-title {
                font-size: 13px;
                font-weight: 600;
                line-height: 1.3;
            }
            .advantiv-homepage-card-description {
                font-size: 10px;
                margin-top: 5px;
                line-height: 1.3;
            }
        }
    }
}

// Mobile screens.
@media only screen and (max-width: 480px) {
    .advantiv-welcome-panel {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 250px;

        .advantiv-welcome-panel-text-container {
            padding: 10px 10px;
        }

        .advantiv-welcome-panel-text {
            font-size: 12px;
        }
    }

    .advantiv-homepage-cards {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        .advantiv-homepage-card-container {
            padding: 15px;
            width: 100%;
            height: 110px;
            border: 1px solid #bcbcbc84;
            margin-top: 10px;
    
            .advantiv-homepage-card {
                display: flex;
                flex-direction: row;
            }
    
            .advantiv-homepage-card-arrow {
                margin-left: auto;
                color: #4baf23;
            }
    
            .advantiv-homepage-card-title {
                font-size: 13px;
                font-weight: 600;
            }
            .advantiv-homepage-card-description {
                font-size: 10px;
                margin-top: 5px;
            }
        }
    }
}


// Tablet screens.
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .advantiv-welcome-panel {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 300px;

        .advantiv-welcome-panel-text-container {
            padding: 15px 10px;
        }

        .advantiv-welcome-panel-text {
            font-size: 13px;
        }
    }

    .advantiv-homepage-cards {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        .advantiv-homepage-card-container {
            padding: 15px;
            width: 100%;
            height: 95px;
            border: 1px solid #bcbcbc84;
            margin-top: 10px;
    
            .advantiv-homepage-card {
                display: flex;
                flex-direction: row;
            }
    
            .advantiv-homepage-card-arrow {
                margin-left: auto;
                color: #4baf23;
            }
    
            .advantiv-homepage-card-title {
                font-size: 13px;
                font-weight: 600;
            }
            .advantiv-homepage-card-description {
                font-size: 10px;
                margin-top: 5px;
            }
        }
    }
}

// Laptop screens.
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .advantiv-welcome-panel {
        margin-bottom: 35px;
        width: 450px;
        
        .advantiv-welcome-panel-text-container {
            padding: 15px 10px;
        }

        .advantiv-welcome-panel-text {
            font-size: 13px;
        }
    }


    .advantiv-homepage-cards {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        .advantiv-homepage-card-container {
            padding: 15px;
            width: 100%;
            height: 80px;
            border: 1px solid #bcbcbc84;
            margin-top: 10px;
    
            .advantiv-homepage-card {
                display: flex;
                flex-direction: row;
            }
    
            .advantiv-homepage-card-arrow {
                margin-left: auto;
                color: #4baf23;
            }
    
            .advantiv-homepage-card-title {
                font-size: 14px;
                font-weight: 600;
            }
            .advantiv-homepage-card-description {
                font-size: 11px;;
                margin-top: 5px;
            }
        }
    }
}

// Desktop screens.
@media only screen and (min-width: 1025px) {
    .advantiv-welcome-panel {
        margin-bottom: 35px;
        width: 700px;

        .advantiv-welcome-panel-text-container {
            padding: 15px 10px;
        }

        .advantiv-welcome-panel-text {
            font-size: 14px;
            line-height: 1.5;
        }
    }

    .advantiv-homepage-cards {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .advantiv-homepage-card-container {
            padding: 15px;
            width: 300px;
            height: 80px;
            border: 1px solid #bcbcbc84;
    
            .advantiv-homepage-card {
                display: flex;
                flex-direction: row;
            }
    
            .advantiv-homepage-card-arrow {
                margin-left: auto;
                color: #4baf23;
            }
    
            .advantiv-homepage-card-title {
                font-size: 14px;
                font-weight: 600;
            }
            .advantiv-homepage-card-description {
                font-size: 11px;;
                margin-top: 5px;
            }
        }
    }
}


.advantiv-welcome-panel-text {
    font-family: $font-family;
    line-height: 1.25rem;
}

.advantiv-getting-started-panel-text {
    font-family: $font-family;
    line-height: 1.25rem;
}

.advantiv-homepage-card-title {
    font-family: $font-family;
    line-height: 1.25rem;
}

.advantiv-homepage-card-description {
    font-family: $font-family;
    line-height: 1.25rem;
}