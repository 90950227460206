.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  min-height: 100vh;
  width: calc(100% - 250px);
  margin-left: 250px;
}

.homepage-logo {
  width: 120px;
  height: 120px;
}