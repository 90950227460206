.content-grid {
    display: grid;
    grid-template-columns: auto 30rem;
    grid-gap: 1rem;
    //margin-top: 2rem;

    &.collapsed {
        grid-template-columns: auto 0px;
        //grid-gap: 0px;

        .details-pane {
            display: none;
        }
    }

    .details-pane {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.05);
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 2px 2px 7.5px rgba(0, 0, 0, 0.07);

        & > * {
            transition: all 0.3s cubic-bezier(0.61, 0.01, 0.38, 1);
            white-space: nowrap;
        }

        .pane-title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 1.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            background: rgb(244, 247, 249);

            i {
                color: rgba(0, 0, 0, 0.66);
                cursor: pointer;
                user-select: none;
            }

            .pane-title {
                margin-bottom: 0;
                font-weight: 700;
                color: rgba(0, 0, 0, 0.66);
            }
        }

        .pane-segment {
            padding: 1rem 1.5rem;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }

            .pane-heading {
                margin-bottom: 0.75rem;
                font-weight: 700;
                color: rgba(0, 0, 0, 0.8);
            }

            p {
                color: rgba(0, 0, 0, 0.66);
            }

            .top-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 1rem;
                gap: 1rem;

                .actions-container {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;

                    .edit-link {
                        color: #636363;
                        text-decoration: none;
                        font-size: 0.8rem;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }

            .identifier-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
                color: rgba(0, 0, 0, 0.66);

                .identifier {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .pi.pi-copy {
                    color: #636363;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
    }
}