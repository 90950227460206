/**
 * Copyright 2023 Springbok Agency
 *
 * When this work is licensed via an agreement you are free to: Share — copy, use and redistribute the material in any
 * medium or format. Under the following terms: Attribution — You must give appropriate credit, provide these terms, and
 * indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor
 * endorses you or your use. NonCommercial — You and/or your partners may not use the material for commercial purposes.
 * NoDerivatives — If you and/or your partners remix, transform, or build upon the material, you may not distribute the
 * modified material externally.
 *
 * Notice: No warranties are given. The licence may not give you all of the permissions necessary for your intended use.
 * For example, other rights such as publicity, privacy, or moral rights may limit how you use the material.
 */


// README: This is the custom theme file for the card component of prime react.
// Every occurance of the class .p-card is replaced with the following code.

$font-family: 'Inter', sans-serif;

.p-card {
    background: #fff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    font-family: $font-family;
}

.p-card .p-card-body {
    padding: 1.25rem;
    font-family: $font-family;
}

.p-card .p-card-title {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px
}

.p-card .p-card-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #6c757d;
    font-family: $font-family;
}

.p-card .p-card-content {
    font-size: 12px;
    font-family: $font-family;
    padding: 1.25rem 0;
}

.p-card .p-card-footer {
    padding: 1.25rem 0 0 0;
    font-family: $font-family;
}